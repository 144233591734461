import { Text, Flex, Image } from "@mantine/core";

interface ProcessCardProps {
  step: string;
  description: string;
  image: string;
}

const ProcessCard = ({ step, description, image }: ProcessCardProps) => {
  return (
    <Flex
      justify={"space-around"}
      align={"center"}
      direction={"column"}
      gap={0}
      w={"100%"}
    >
      <Image w={{ base: 65, md: 75 }} src={image} alt={description} />
      <Text
        fw={{ base: 650, md: 800 }}
        fz={{ base: "14px", md: "16px" }}
        c={"dark.13"}
      >
        {step.toUpperCase()}
      </Text>
      <Text
        fw={{ base: 500, md: 600 }}
        fz={{ base: "16px", md: "20px" }}
        c={"dark.9"}
      >
        {description}
      </Text>
    </Flex>
  );
};

export default ProcessCard;
