import { Box, Container, Group } from "@mantine/core";
import {
  MdOutlineArrowForwardIos,
  MdOutlineArrowBackIos,
} from "react-icons/md";
import { useEffect, useState } from "react";
import PriceCard from "../cards/priceCard";
import { useGetAllServices } from "../../../hooks/user/user.services";
import { useCurrentCountry } from "../../../hooks/country";
import { BioDataIcon } from "../../../atoms/svgs/biodataIcon";
import { BirthIcon } from "../../../atoms/svgs/birthIcon";
import { DeathIcon } from "../../../atoms/svgs/deathIcon";
import { MaritalIcon } from "../../../atoms/svgs/maritalIcon";
import { NecoIcon } from "../../../atoms/svgs/necoIcon";
import { NYSCIcon } from "../../../atoms/svgs/nyscIcon";
import { ProfessionalIcon } from "../../../atoms/svgs/professionalIcon";
import { UniversityIcon } from "../../../atoms/svgs/universityIcon";
import { WaecIcon } from "../../../atoms/svgs/waecIcon";
import useResponsive from "../../../hooks/useResponsive";

export const certificates = [
  {
    ServiceName: "Waec",
    Price: 20000,
    Duration: "Duration 1-2 working days",
    Description:
      "Get a detailed verification of WAEC results to confirm their authenticity for official or employment purposes.",
    icon: <WaecIcon />,
    Link: "/service/certificate",
    ServiceCode: "WAEC",
    label: "Waec",
  },
  {
    ServiceName: "University Degree",
    Price: 40000,
    Duration: "Duration 1-6 weeks",
    Description:
      "Ensure the validity of university degrees and confirm their legitimacy for academic or professional use.",
    icon: <UniversityIcon />,
    Link: "/service/certificate",
    ServiceCode: "UNIVERSITY",
    label: "University",
  },
  {
    ServiceName: "Neco Result",
    Price: 20000,
    Duration: "Duration 1-2 working days",
    Description:
      "Verify NECO results with ease to ensure their credibility for educational or employment purposes.",
    icon: <NecoIcon />,
    Link: "/service/certificate",
    ServiceCode: "NECO",
    label: "Neco",
  },
  {
    ServiceName: "NYSC Certificate",
    Price: 20000,
    Duration: "Duration 1-4 weeks",
    Description:
      "Confirm the authenticity of NYSC certificates to validate an individual's service history.",
    icon: <NYSCIcon />,
    Link: "/service/certificate",
    ServiceCode: "NYSC",
    label: "Nysc",
  },
  {
    ServiceName: "Professional Certificates",
    Price: 20000,
    Duration: "Duration 1-4 weeks",
    Description:
      "Authenticate professional certificates to ensure they meet recognized industry standards.",
    icon: <ProfessionalIcon />,
    Link: "/service/certificate",
    ServiceCode: "PROFESSIONAL",
    label: "Professional",
  },
  {
    ServiceName: "Biodata",
    Price: 20000,
    Duration: "Duration 1-4 weeks",
    Description:
      "Verify vital records like birth, marital, or death certificates for accurate personal documentation.",
    icon: <BioDataIcon />,
    Link: "/service/certificate",
    ServiceCode: "BIODATA",
    label: "Biodata",
  },
  {
    ServiceName: "Death Certificate",
    Price: 20000,
    Duration: "Duration 1-4 weeks",
    Description:
      "Ensure the accuracy of death certificates for legal, official, or personal verification.",
    icon: <DeathIcon />,
    Link: "/service/certificate",
    ServiceCode: "DEATH",
    label: "Death",
  },
  {
    ServiceName: "Birth Certificate",
    Price: 20000,
    Duration: "Duration 1-4 weeks",
    Description:
      "Authenticate birth certificates to confirm identity and personal history details.",
    icon: <BirthIcon />,
    Link: "/service/certificate",
    ServiceCode: "BIRTH",
    label: "Birth",
  },
  {
    ServiceName: "Marriage Certificate",
    Price: 20000,
    Duration: "Duration 1-4 weeks",
    Description:
      "Validate marriage certificates for legal, employment, or personal documentation purposes.",
    icon: <MaritalIcon />,
    Link: "/service/certificate",
    ServiceCode: "MARITAL",
    label: "Marriage",
  },
  {
    ServiceName: "Other Certificate",
    Price: 20000,
    Duration: "Duration 1-4 weeks",
    Description:
      "Get verification for other certificates, ensuring authenticity and validity for official purposes.",
    icon: <MaritalIcon />,
    Link: "/service/certificate",
    ServiceCode: "OTHERS_CERTIFICATE",
    label: "Others",
  },
];

export function CertificateServices() {
  const { isMobile, isTablet } = useResponsive();
  const { data: servicesRes } = useGetAllServices();
  const { countryData } = useCurrentCountry();
  const [servicesToDisplay, setServicesToDisplay] = useState(3);

  const updatedCertificates = () => {
    return servicesRes?.data.map((service: any) => {
      // Find matching certificate based on ServiceCode
      const certsData = certificates.find((certificate) => {
        return service.serviceCode === certificate.ServiceCode;
      });

      // If certsData is found, extend service with additional fields, otherwise return service as is
      if (certsData) {
        return {
          ...service,
          description: certsData?.Description,
          duration: certsData?.Duration,
          icon: certsData?.icon,
          link: certsData?.Link,
          label: certsData?.label,
        };
      }

      // Return the original service object if no match is found
      return service;
    });
  };

  const servicesUpdated = updatedCertificates();

  const [startIndex, setStartIndex] = useState(0);

  const shownServices = servicesUpdated?.slice(
    startIndex,
    startIndex + servicesToDisplay
  );

  const handleNext = () => {
    if (startIndex + servicesToDisplay < servicesUpdated?.length) {
      setStartIndex((prev) => prev + 1);
    }
  };

  const handlePrevious = () => {
    if (startIndex > 0) {
      setStartIndex((prev) => prev - 1);
    }
  };

  useEffect(() => {
    isMobile
      ? setServicesToDisplay(1)
      : isTablet
      ? setServicesToDisplay(2)
      : setServicesToDisplay(3);
  }, [isMobile, isTablet]);

  return (
    <Box bg="light-grey.3">
      <Container size={"lg"} p={0}>
        <Group p={"lg"} align="center" justify="center" wrap={"nowrap"}>
          <MdOutlineArrowBackIos
            style={{ cursor: "pointer" }}
            size={isMobile ? 20 : 30}
            onClick={handlePrevious}
            color={startIndex > 0 ? "#E86217" : "#ABA7A7"}
          />
          <Group align={"center"} wrap={"wrap"} justify="center">
            {shownServices?.map((service: any) => (
              <PriceCard
                key={service.id}
                description={service.description}
                icon={service.icon}
                serviceType={service.label}
                price={{
                  amount: service.dollarAmount,
                  currency: "$",
                }}
                to={service.link}
              />
            ))}
          </Group>
          <MdOutlineArrowForwardIos
            style={{ cursor: "pointer" }}
            size={isMobile ? 20 : 30}
            onClick={handleNext}
            color={
              startIndex + servicesToDisplay < servicesUpdated?.length
                ? "#E86217"
                : "#ABA7A7"
            }
          />
        </Group>
      </Container>
    </Box>
  );
}
