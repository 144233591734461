import { LandingLayout } from "../components/layouts/landing";
import Services from "../components/primitives/certificatesPage/services";
import AboutImage from "../components/primitives/aboutPage/aboutImage";
import ServicesImage from "../components/assets/images/servicesWeOfferImage.png";
import { withLanding } from "../hoc/withLanding";

const Certificates = () => {
  return (
    <LandingLayout>
      <AboutImage image={ServicesImage} headerText={"Services we offer"} />
      <Services />
    </LandingLayout>
  );
};

export default withLanding(Certificates);
