import { Box, Container, Group, Text } from "@mantine/core";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  XIcon,
} from "../../components/assets/icons";
import { AddressBlock } from "../../components/primitives/footer/addressBlock";
import { FooterLinksBlock } from "../../components/primitives/footer/footerBlock";

const socialLinks = [
  {
    link: "https://www.linkedin.com",
    icon: <LinkedInIcon />,
  },
  {
    link: "https://www.instagram.com",
    icon: <InstagramIcon />,
  },
  {
    link: "https://www.twitter.com",
    icon: <XIcon />,
  },
  {
    link: "https://www.facebook.com",
    icon: <FacebookIcon />,
  },
];

const FooterLinksData = [
  {
    title: "Company",
    links: [
      {
        link: "/about",
        label: "About us",
      },
      {
        link: "/",
        label: "Careers",
      },
      {
        link: "/contact",
        label: "Contact",
      },
    ],
  },
  {
    title: "Products",
    links: [
      {
        link: "/certificates",
        label: "Certificate Verification",
      },
      // {
      //   link: "/certificates",
      //   label: "Document verification",
      // },
      {
        link: "https://iverify.ng/services/identity",
        label: "Employee verification",
        isExternalLink: true,
      },
      {
        link: "https://iverify.ng/services/guarantor",
        label: "Guarantor verification",
        isExternalLink: true,
      },
      {
        link: "https://app.iverify.ng/",
        label: "Address verification",
        isExternalLink: true,
      },
    ],
  },
  {
    title: "Legal",
    links: [
      {
        link: "/legal?tab=tos",
        label: "Terms of Service",
      },
      {
        link: "/legal?tab=privacy",
        label: "Privacy Policy",
      },
      {
        link: "/legal?tab=data",
        label: "Data Protection Policy",
      },
    ],
  },
];

export const Footer = () => {
  return (
    <Box bg="dark" pt={{ base: "2em", md: "7em" }}>
      <Container size={"lg"}>
        <Group justify={"space-between"} align={"start"}>
          <Box
            maw={{ base: "100%", md: "17.2em" }}
            pb={{ base: "2.5em", md: "none" }}
          >
            <AddressBlock
              address={`Level 3, Suite 2, Dominion Plaza, 140/141 Lekki - Epe Expy, Igbo Efon, Lagos`}
              socialLinks={socialLinks}
            />
          </Box>
          {FooterLinksData.map((linksData, index) => (
            <FooterLinksBlock
              key={index}
              title={linksData.title}
              links={linksData.links}
            />
          ))}
        </Group>
        <Group
          mt={"3rem"}
          pt={"1.2em"}
          pb={"2em"}
          style={(theme) => ({
            borderTop: `0.5px solid ${theme.colors["light-grey"][8]}`,
          })}
        >
          <Text c="light-grey.1" size="sm">
            ©2024 kpali.ng. All rights reserved
          </Text>
        </Group>
      </Container>
    </Box>
  );
};
