import { IconProps } from "./overviewIcon";

export const UniversityIcon = ({
  className,
  width,
  height,
  fill,
}: IconProps) => (
  <svg
    width={width || "60"}
    height={height || "60"}
    className={className}
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30 7.5L2.5 22.5L12.5 27.95V42.95L30 52.5L47.5 42.95V27.95L52.5 25.225V42.5H57.5V22.5L30 7.5ZM47.05 22.5L30 31.8L12.95 22.5L30 13.2L47.05 22.5ZM42.5 40L30 46.8L17.5 40V30.675L30 37.5L42.5 30.675V40Z"
      //   fill="#E86217"
      fill={fill || "black"}
    />
  </svg>
);
