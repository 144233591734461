import { Container, Title, Divider, Flex } from "@mantine/core";

interface AboutImageProps {
  image: string;
  headerText: string | JSX.Element;
}

function AboutImage({ headerText }: AboutImageProps) {
  return (
    <Container size={"lg"} w={"100%"}>
      <Flex
        justify={"center"}
        align={"center"}
        gap={"0.5em"}
        p={"2.5em 0"}
        direction={"column"}
      >
        <Title fw={600} fz={{ base: "2em", sm: "3em", md: "61px" }} c="dark.4">
          {headerText}
        </Title>
        <Divider color={"orange"} w={50} size={4} />
      </Flex>
    </Container>
  );
}

export default AboutImage;
