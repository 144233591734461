import { Container, SimpleGrid, Stack, Title } from "@mantine/core";
import GreyBackground from "../backgrounds/greyBackground";
import ProcessCard from "../cards/processCard";
import CreateAccount from "../../assets/images/create-account-2.png";
import GetFeedback from "../../assets/images/get-feedback-2.jpg";
import UploadCertificate from "../../assets/images/upload-doc.png";

const verificationProcessData = [
  {
    headerText: "Step 1",
    subText: "Create an Account",
    image: CreateAccount,
  },
  {
    headerText: "Step 2",
    subText: "Upload Credentials",
    image: UploadCertificate,
  },
  {
    headerText: "Step 3",
    subText: "Get feedback",
    image: GetFeedback,
  },
];

function VerificationProcess() {
  return (
    <GreyBackground>
      <Container size={"lg"}>
        <Stack align={"center"}>
          <Title
            order={2}
            fw={600}
            fz={{ base: "1.75em", sm: "2.5em", md: "49px" }}
            c="dark.4"
          >
            Our 3 Step Verification Process
          </Title>
          <SimpleGrid
            cols={{ base: 1, sm: 2, md: 3 }}
            spacing={"xl"}
            mt={50}
            mb={32}
            w={"100%"}
          >
            {verificationProcessData.map((process, index) => (
              <ProcessCard
                key={index}
                step={process.headerText}
                description={process.subText}
                image={process.image}
              />
            ))}
          </SimpleGrid>
        </Stack>
      </Container>
    </GreyBackground>
  );
}

export default VerificationProcess;
