import { Box, Button, Card, Divider, Group, Stack, Text } from "@mantine/core";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";

export type PriceType = {
  currency: string;
  amount: number;
};

interface PriceCardProps {
  description: string;
  price: PriceType;
  icon: JSX.Element;
  serviceType: string;
  to: string;
}

const PriceCard = ({
  description,
  price,
  icon,
  serviceType,
  to,
}: PriceCardProps) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Card
      shadow="sm"
      padding="lg"
      radius="25px"
      w={{ base: "280px", md: "300px" }}
      h={"350px"}
      style={{
        border: "1px solid #E2E0E0",
        cursor: "pointer",
        boxShadow: isHovered ? "0px 4px 10px rgba(80, 80, 80, 0.1)" : "none",
        transition: "all 0.3s",
        animation: "ease-in",
        transform: isHovered ? "scale(1.01)" : "scale(1)",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Stack mt="md" mb="xs">
        <Box
          w={"80px"}
          h={"80px"}
          style={{
            backgroundColor: isHovered ? "#FFDFCC4D" : "#F8F8F8",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "15px",
          }}
        >
          {!isHovered ? icon : React.cloneElement(icon, { fill: "#E86217" })}
        </Box>
        <Group>
          <Text fw={500} c="#5C5858" fz={"24px"}>
            {serviceType}
          </Text>
          <Text fz={"24px"} fw={800} c="#5C5858">
            {`${price.currency}${price.amount}`}
          </Text>
        </Group>

        <Text fz={"14px"} c={"#5C5858"} fw={500}>
          {description}
        </Text>
        <Divider c={"#E2E0E0"} />
        <Box>
          <NavLink to={to} style={{ textDecoration: "none" }}>
            <Button
              radius="15px"
              size={"lg"}
              variant={isHovered ? "filled" : "outline"}
            >
              Verify now
            </Button>
          </NavLink>
        </Box>
      </Stack>
    </Card>
  );
};

export default PriceCard;
