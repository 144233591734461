import {
  TextInput,
  Textarea,
  SimpleGrid,
  Group,
  Title,
  Button,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import styles from "./form.module.css";
import { ContactValidationSchema } from "../../../schema/contact/contact";
import { yupResolver } from "mantine-form-yup-resolver";
import { postContactForm } from "../../../hooks/contact/contact.hook";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

function GetInTouchSimple() {
  const form = useForm({
    initialValues: {
      firstName: "",
      phoneNumber: "",
      email: "",
      subject: "",
      message: "",
    },
    validate: yupResolver(ContactValidationSchema),
  });

  const contactMutation = useMutation({
    mutationFn: postContactForm,
    onSuccess: (data) => {
      toast.success(data.message);
      form.reset();
    },
  });

  return (
    <form
      style={{
        flex: 1,
        backgroundColor: "white",
        padding: "2.5em",
        borderRadius: "1em",
        border: "1px #E8E8E8 solid",
      }}
      onSubmit={form.onSubmit((data) => contactMutation.mutate(data))}
    >
      <Title order={2} size="h3" fw={900} ta="left">
        Send Message
      </Title>

      <SimpleGrid cols={{ base: 1, sm: 2 }} mt={"sm"}>
        <TextInput
          placeholder="Firstname"
          name="firstName"
          mt={{ base: "md", sm: "md" }}
          required
          classNames={{ input: styles.textarea }}
          {...form.getInputProps("firstName")}
        />
        <TextInput
          placeholder="Email"
          name="email"
          type="email"
          mt={{ base: "md", sm: "md" }}
          required
          classNames={{ input: styles.textarea }}
          {...form.getInputProps("email")}
        />
      </SimpleGrid>

      <SimpleGrid cols={{ base: 1, sm: 2 }} mt={"sm"}>
        <TextInput
          placeholder="Phone number"
          name="phonenumber"
          required
          error={form.errors.phonenumber}
          classNames={{ input: styles.textarea }}
          {...form.getInputProps("phoneNumber")}
        />
        <TextInput
          placeholder="Subject"
          name="subject"
          required
          classNames={{ input: styles.textarea }}
          {...form.getInputProps("subject")}
        />
      </SimpleGrid>

      <Textarea
        mt="md"
        placeholder="Message"
        name="message"
        maxRows={10}
        minRows={5}
        autosize
        required
        classNames={{ input: styles.textarea }}
        {...form.getInputProps("message")}
      />

      <Group justify="start" mt="xl">
        <Button type="submit" size="md" disabled={contactMutation.isLoading}>
          Send message
        </Button>
      </Group>
    </form>
  );
}

export default GetInTouchSimple;
