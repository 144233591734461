import { IconProps } from "./overviewIcon";

export const DeathIcon = ({ className, width, height, fill }: IconProps) => (
  <svg
    width={width || "52"}
    height={height || "52"}
    className={className}
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.8305 6.50112L14.1591 18.616L19.1143 45.4827L32.8434 45.4979L37.8419 18.6355L33.19 6.51304L18.8305 6.50112ZM34.6785 4.34746L17.3441 4.33337L11.9166 18.408L17.3095 47.6472L34.6428 47.6667L40.0833 18.4308L34.6785 4.34746Z"
      fill={fill || "black"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.9165 19.4968V28.1667H27.0832V19.4968H30.3332V17.3301H27.0832V14.0834H24.9165V17.3301H21.6665V19.4968H24.9165Z"
      fill={fill || "black"}
    />
  </svg>
);
