import { Box, Button, Flex, Group, Stack, Text, Title } from "@mantine/core";
import { KpaliLogo } from "../../assets/icons";
import classes from "./consent.module.css";
import moment from "moment";

export function ConsentForm({
  applicantName,
  //   subjectName,
  onNoClick,
  onYesClick,
}: {
  applicantName: string;
  onNoClick: () => void;
  onYesClick: () => void;
  //   subjectName: string;
}) {
  return (
    <div className={classes.container}>
      <header className={classes.header}>
        <KpaliLogo size={28} />
      </header>

      <Box>
        <Stack align="center">
          <Title fw={"bold"} fz={"md"}>
            {" "}
            CONSENT TO VERIFICATION
          </Title>
          <Box>
            <Text fz={"xs"}>
              {` I, the undersigned, hereby authorize Kpali.ng, a subsidiary of Rentcheck Referencing Company  Ltd, to obtain a pre-
employment background screening report on me in order to verify my particulars as provided in my curriculum 
vitae to my employer, for the purpose of my application by and through the employer’s service provider, Rentcheck 
Referencing Company Limited under the brand name Kpali.ng, in accordance with the Company’s Data Protection 
and Privacy Policy. The above-mentioned report will include job related information, such as identity, education 
history, professional qualifications and memberships, criminal history, employment history, work references, credit 
checks and other applicable public record information. I hereby declare that l understand and will cooperate with 
persons conducting the screening and to assist if more information is required or when approaching stakeholders 
including but not limited to former employer(s), educational establishments, government agencies and any other 
relevant entity.`}
              <br />
              <br />
              <Text fz={"xs"}>
                {` I further authorize all current or previous employers to discuss my relevant personal and employment history with 
Rentcheck Referencing Company  Limited with my consent to release of such information orally or in writing, and 
hereby release them from all liability. I further authorize my employer and Rentcheck Referencing Company  
Limited to disclose a copy of this authorization to any person(s), educational establishment(s), former employer(s), 
business entity(ies) or public body(ies) (“Third Party”) and for this authorization to act as my specific consent to any 
such Third Party for the disclosure to my employer or to Rentcheck Referencing Company of any personal data 
about me. I hereby release the employer, Rentcheck Referencing Company  limited and any Third Parties from any 
claims, costs, expenses or rights of action of any kind whatsoever (“Claims”) which I (or others on my behalf) may 
have against the employer or Rentcheck Referencing Company limited arising directly or indirectly out of or in 
connection with the provision of the preemployment background screening report. This authorization given in this 
Consent Form expires automatically if my application is rejected or following termination of my employment with 
the Company if my application is successful. I certify that the information provided in my application for 
employment is true and correct and that my job application and /or employment might be terminated based on 
any false or misleading information subject to local law provisions and jurisdiction. `}
              </Text>
            </Text>
          </Box>
        </Stack>
        <Group justify="space-between" mt={"lg"}>
          <Flex>
            <Box>
              <Text fz="sm">
                Applicant Signature: <span>{applicantName}</span>{" "}
              </Text>
              <Text fz="sm">
                Date:<span>{moment(new Date()).format("YYYY-MM-DD")}</span>{" "}
              </Text>
            </Box>
          </Flex>
          {/* <Flex>
            <Box>
              <Text fz="sm">
                Subject Signature: <span>{subjectName}</span>{" "}
              </Text>
              <Text fz="sm">
                Date:<span>{moment(new Date()).format("YYYY-MM-DD")}</span>{" "}
              </Text>
            </Box>
          </Flex> */}
        </Group>
        <Group
          gap={"10rem"}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button onClick={onNoClick} bg="gray">
            No, I don't have consent
          </Button>
          <Button onClick={onYesClick}>Yes, I have consent</Button>
        </Group>
      </Box>
    </div>
  );
}
