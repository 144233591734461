import React, { useState, useMemo } from "react";
import { Stack, em, Text, Box, Flex, Group } from "@mantine/core";
import { useForm, SubmitHandler, useFieldArray } from "react-hook-form";
import UserLayout from "../../../../layouts/userLayout";
import { useGetAllServices } from "../../../../../hooks/user/user.services";
import { ServiceCodes } from "../../../../../enums/enums";
import ServicesLoader from "../../../shared/skeletonLoader/servicesLoader";

function DocumentVerificaton() {
  const { data, isPending: isServicesPending } = useGetAllServices();
  const [document, setDocument] = useState("");
  const [headerData, setHeaderData] = useState(["Document Verification"]);

  const initialFormData = {
    documents: [
      {
        subjectName: "",
        applicantName: "",
        passportNo: "",
        subjectDOB: "",
        driversLicenseNo: "",
        votersCardNo: "",
        documentNo: "",
        documentUrl: "",
        documentType: document,
      },
    ],
  };

  const { reset } = useForm<any>({
    defaultValues: initialFormData,
    // resolver: yupResolver(CreateCertificateRequestSchema),
  });
  // const { fields, append, remove } = useFieldArray({
  //   control,
  //   name: "documents",
  // });
  // const watchDocument = watch("documents");

  // const controlledDocuments = fields.map((document, index) => {
  //   return {
  //     ...document,
  //     ...watchDocument[index],
  //   };
  // });

  // const onSubmit: SubmitHandler<any> = (data) => {
  //   // mutate(data);
  // };

  // const onPreview: SubmitHandler<any> = (data) => {
  //   // setIsPreviewOpen(true);
  // };

  // const handleAddMore = () => {
  //   append(initialFormData.documents[0]);
  // };

  // const handleRemove = (index: number) => {
  //   remove(index);
  // };

  const DocumentServices = useMemo(() => {
    return [
      {
        label: "INTERNATIONAL PASSPORT",
        value: "INTERNATIONALPASSPORT",
        serviceCode: ServiceCodes.INTERNATIONAL_PASSPORT,
      },
      {
        label: "DRIVERS LICENSE",
        value: "DRIVERSLICENSE",
        serviceCode: ServiceCodes.DRIVERS_LICENSE,
      },
      {
        label: "NATIONAL IDENTITY NUMBER",
        value: "NIN",
        serviceCode: ServiceCodes.NIN,
      },
      {
        label: "VOTERS CARD",
        value: "VOTERSCARD",
        serviceCode: ServiceCodes.VOTERS_CARD,
      },
    ];
  }, []);

  const DocumentServicesWithAvailaibilty = DocumentServices.map((dataType) => {
    const matchingEntry = data?.data?.data?.find(
      (entry: any) => entry.serviceCode === dataType?.serviceCode
    );
    if (matchingEntry) {
      return {
        ...dataType,
        price: matchingEntry?.costAmount,
        id: matchingEntry?.id,
        available: matchingEntry.available || false,
        visible: matchingEntry.visible || false,
      };
    } else {
      return {
        ...dataType,
        id: matchingEntry?.id,
        available: false,
        visible: false,
        price: matchingEntry?.costAmount,
      };
    }
  });

  const handleCertificateChange = (value: string, label: string) => {
    reset();
    setDocument(value);
    setHeaderData(["Document Verification", label]);
  };

  return (
    <UserLayout title="Services">
      <Stack>
        <Group gap={"0em"}>
          {headerData.map((data, index) => (
            <React.Fragment key={index}>
              {index !== 0 && <span style={{ marginRight: "0.5em" }}></span>}
              <Text
                c={index === headerData.length - 1 ? "dark.4" : "light-grey.8"}
                {...(index === headerData.length - 1 && { fw: 500 })}
                key={index}
              >
                {index === 0
                  ? data.charAt(0).toUpperCase() + data.slice(1).toLowerCase()
                  : " > " +
                    data.charAt(0).toUpperCase() +
                    data.slice(1).toLowerCase()}
              </Text>
            </React.Fragment>
          ))}
        </Group>
        <Flex
          gap={"2.5em"}
          wrap={"wrap"}
          direction={{ base: "column", sm: "row", md: "row" }}
          align={{ base: "center", sm: "flex-start" }}
        >
          <Box
            style={{
              borderRadius: "1.5em",
              border: "1.30px solid #E8E8E8",
              boxShadow: "box-shadow: 0px 4px 16px 0px #0000000D",
            }}
            bg={"light-grey.4"}
            w={"370px"}
            pt={"3em"}
            pb={"1.5em"}
          >
            <Stack gap={"0em"}>
              <Group p={"1em 1.5em"} bg={"light-grey.3"} w={"100%"}>
                <Text fz={em(14)} fw={500} w={"10%"} c={"light-grey.8"}>
                  SRNO
                </Text>
                <Text fz={em(14)} fw={500} c={"light-grey.8"}>
                  SERVICES
                </Text>
              </Group>
              {!isServicesPending && (
                <>
                  {DocumentServicesWithAvailaibilty.map((data, index) => (
                    <>
                      {data.visible && (
                        <Group
                          style={{
                            textDecoration: data.available
                              ? ""
                              : "line-through",
                            paddingTop: "0.75em",
                            paddingBottom: "0.75em",
                            paddingLeft:
                              document === data.value ? "2.5%" : "4%",
                            cursor: "pointer",
                            borderBottom: "0.5px solid #E8E8E8",
                            borderLeft:
                              document === data.value
                                ? "5px solid #E86217"
                                : "",
                            backgroundColor:
                              document === data.value ? "#FFF2EB" : "",
                          }}
                          key={index}
                          c={"light-grey.8"}
                          p={"1em 0"}
                          w={"100%"}
                          onClick={() =>
                            data?.available
                              ? handleCertificateChange(data.value, data.label)
                              : null
                          }
                        >
                          <Text pl={"1.5em"} fw={600} w={"10%"}>
                            {index + 1}
                          </Text>
                          <Group
                            justify={"space-between"}
                            w={"80%"}
                            pr={"1.5em"}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <Text fw={600}>{data.label}</Text>
                          </Group>
                        </Group>
                      )}
                    </>
                  ))}
                </>
              )}
              {isServicesPending && <ServicesLoader />}
            </Stack>
          </Box>
        </Flex>
      </Stack>
    </UserLayout>
  );
}

export default DocumentVerificaton;
