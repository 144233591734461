import {
  Image,
  Title,
  Text,
  Stack,
  Flex,
  Button,
  Box,
  Container,
} from "@mantine/core";
import { Link } from "react-router-dom";
import HeroImage from "../../assets/images/hero2-image.png";
import useResponsive from "../../../hooks/useResponsive";
import WhiteSoulImage from "../../assets/images/white-soul-logo.png";
import FidelityImage from "../../assets/images/fidelity-logo.svg";
import LeadingPLCImage from "../../assets/images/leasing.png";
import FemabImage from "../../assets/images/femab.png";
import TariusImage from "../../assets/images/tarius.png";
import StanBicBankImage from "../../assets/images/stanbic.png";
import AccessImage from "../../assets/images/access-logo.png";
import ZenithImage from "../../assets/images/zenith-logo.png";
import TotalEnergiesImage from "../../assets/images/total-energies.png";
import FirstAllyImage from "../../assets/images/first-ally.png";
import TerraworkImage from "../../assets/images/terrawork.png";
import classes from "./css.module.css";

export function HeroBullets() {
  const partners = [
    {
      name: "Stanbic Bank",
      image: StanBicBankImage,
    },
    {
      name: "Total Energies",
      image: TotalEnergiesImage,
    },
    {
      name: "First Ally",
      image: FirstAllyImage,
    },
    {
      name: "Terrawork",
      image: TerraworkImage,
    },
    {
      name: "Access Bank",
      image: AccessImage,
    },
    {
      name: "Zenith Bank",
      image: ZenithImage,
    },

    {
      name: "Fidelity Bank",
      image: FidelityImage,
    },
    {
      name: "Leading PLC",
      image: LeadingPLCImage,
    },
    {
      name: "White Soul Group",
      image: WhiteSoulImage,
    },
    {
      name: "Femab",
      image: FemabImage,
    },
    {
      name: "Tarius",
      image: TariusImage,
    },
  ];

  const { isTablet } = useResponsive();

  return (
    <Container size={"lg"}>
      <Stack style={{ padding: "3.5em 0em" }}>
        <Flex
          justify={"center"}
          align={"center"}
          gap={"1em"}
          direction={{ base: "column-reverse", md: "row" }}
        >
          <Stack
            gap={"0.5em"}
            style={{
              flex: 1,
            }}
          >
            <Title
              order={1}
              fw={500}
              fz={{ base: "2em", sm: "3em", md: "61px" }}
              c="dark.4"
            >
              Verify{" "}
              <Text component="span" inherit fw={700}>
                academic
              </Text>{" "}
              {!isTablet && <br />}
              and{" "}
              <Text component="span" inherit fw={700}>
                professional
              </Text>{" "}
              {!isTablet && <br />}
              certificates
            </Title>
            <Text
              fz={{ base: "1.0em", md: "16px" }}
              c="dark.9"
              mb={{ base: "1.25em", md: "1.5em" }}
            >
              A robust and secure platform designed to streamline the
              authentication process for all your important documents.
            </Text>

            <Link
              to={"/signup"}
              style={{
                textDecoration: "none",
              }}
            >
              <Button
                radius="7px"
                size="md"
                p={{ base: "0.5em 1em", md: "0.5em 2em" }}
              >
                <Text c="light-grey.4" fw={700} size="14px">
                  Get started
                </Text>
              </Button>
            </Link>
          </Stack>
          <Box
            style={{
              flex: 1,
            }}
          >
            <Image src={HeroImage} />
          </Box>
        </Flex>
        <Flex
          justify={{ base: "center", md: "space-between" }}
          align={"center"}
          direction={{ base: "column", md: "row" }}
          gap={"1em"}
          style={{ padding: "2em 0em" }}
          w={"100%"}
          maw={"100%"}
        >
          <Text
            c="dark.12"
            ta={"center"}
            fz={{ base: "18px", md: "20px" }}
            fw={600}
            w={{ base: "100%", md: "30%" }}
          >
            Trusted by our leading partners
          </Text>
          <Box
            style={{ overflow: "hidden", position: "relative" }}
            w={{ base: "100%", md: "70%" }}
            maw={{ base: "100%", md: "70%" }}
          >
            <Flex
              gap="2em"
              justify="flex-start"
              className={classes["sliding-partners"]}
            >
              {partners.map((partner, index) => (
                <Image
                  key={index}
                  src={partner.image}
                  alt={partner.name}
                  width={45}
                  height={45}
                />
              ))}
            </Flex>
          </Box>
        </Flex>
      </Stack>
    </Container>
  );
}
