// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes css_slide-left__62hs1 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.css_sliding-partners__woNyE {
  animation: css_slide-left__62hs1 10s linear infinite;
}
`, "",{"version":3,"sources":["webpack://./src/components/primitives/homePage/css.module.css"],"names":[],"mappings":"AAAA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,2BAA2B;EAC7B;AACF;;AAEA;EACE,oDAAyC;AAC3C","sourcesContent":["@keyframes slide-left {\n  0% {\n    transform: translateX(0);\n  }\n  100% {\n    transform: translateX(-50%);\n  }\n}\n\n.sliding-partners {\n  animation: slide-left 10s linear infinite;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sliding-partners": `css_sliding-partners__woNyE`,
	"slide-left": `css_slide-left__62hs1`
};
export default ___CSS_LOADER_EXPORT___;
