import * as Yup from "yup";

export const ContactValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .min(1, "Name is required")
    .required("Name is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  subject: Yup.string().trim().optional(),
  message: Yup.string()
    .trim()
    .min(1, "Message is required")
    .required("Message is required")
    .test(
      "no-links",
      "Message must not contain links",
      (value) =>
        !/(http:\/\/|https:\/\/|www\.|\.com|\.net|\.org|\.io|\.edu|\.gov|\.co)/i.test(
          value || ""
        )
    ),
  phoneNumber: Yup.string().optional(),
});
