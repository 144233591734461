// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.consent_header__9x23z {
    height: 56px;
    margin-bottom: 40px;
    padding-bottom: 16px;
    border-bottom: 2px solid #bfc3c8;
  }

  .consent_container__9tk4W {
    padding: 16px
  }`, "",{"version":3,"sources":["webpack://./src/components/primitives/shared/consent.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;IACnB,oBAAoB;IACpB,gCAAgC;EAClC;;EAEA;IACE;EACF","sourcesContent":[".header {\n    height: 56px;\n    margin-bottom: 40px;\n    padding-bottom: 16px;\n    border-bottom: 2px solid #bfc3c8;\n  }\n\n  .container {\n    padding: 16px\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `consent_header__9x23z`,
	"container": `consent_container__9tk4W`
};
export default ___CSS_LOADER_EXPORT___;
