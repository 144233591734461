import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Code, rem, Stack, Anchor } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import { useResponsive } from "../../hooks/useResponsive";
import { KpaliLogo } from "../../components/assets/icons";
import { AllRequestsIcon } from "../../atoms/svgs/allRequestsIcon";
import { ServicesIcon } from "../../atoms/svgs/servicesIcon";
import { OverviewIcon } from "../../atoms/svgs/overviewIcon";
import { CompletedIcon } from "../../atoms/svgs/completedIcon";
import { RequestedIcon } from "../../atoms/svgs/requestedIcon";
import { MultipleUsersIcon } from "../../atoms/svgs/multipleUserIcon";
import { PendingIcon } from "../../atoms/svgs/pendingIcon";

const userdshboardData = [
  { link: "/overview", label: "Overview", icon: <OverviewIcon /> },

  {
    label: "Service",
    icon: <ServicesIcon />,
    initiallyOpened: false,
    children: [
      { label: "Certificate Verification", link: "/service/certificate" },
      { label: "Document Verification", link: "/service/document" },
    ],
  },
  { link: "/requested", label: "Requested Services", icon: <RequestedIcon /> },
  { link: "/pending", label: "Pending", icon: <PendingIcon /> },
  { link: "/completed", label: "Completed", icon: <CompletedIcon /> },
  { link: "/requested", label: "All Requests", icon: <AllRequestsIcon /> },
  {
    label: "Other Services",
    icon: <MultipleUsersIcon />,
    initiallyOpened: true,
    children: [
      { label: "Employee Verification", link: "https://www.iverify.ng/" },
      {
        label: "Tenant Verification",
        link: "https://www.iverify.ng/services/tenant",
      },
      {
        label: "Criminal Check",
        link: "https://www.iverify.ng/services/criminal-record",
      },
      {
        label: "Guarantor Verification",
        link: "https://www.iverify.ng/services/guarantor",
      },
    ],
  },
];

export function UsernavBar({ toggle }: { toggle?: () => void }) {
  const navigate = useNavigate();
  const [hovered, setHovered] = useState("");
  const { isTablet } = useResponsive();
  const active = window.location.pathname;
  const handleHover = (label: string) => () => {
    setHovered(label);
  };
  const isActive = (link: string) => {
    return active.includes(link);
  };
  const isChildActive = (links: string[]) => {
    return links.some((link) => isActive(link));
  };
  const [data, setData] = useState(userdshboardData);
  const handleToggle = (label: string) => {
    const newData = data.map((item) => {
      if (item.label === label) {
        item.initiallyOpened = !item.initiallyOpened;
      }
      return item;
    });
    setData(newData);
  };
  useEffect(() => {
    if (active.includes("service")) {
      data[1].initiallyOpened = true;
    }
  });

  const links = data.map((item) => {
    const isAnyChildActive = isChildActive(
      item.children ? item.children.map((child) => child.link) : []
    );
    const isHovered = hovered === item.label;
    return item.children ? (
      <div key={item.label}>
        <Anchor
          onClick={() => handleToggle(item.label)}
          key={item.label}
          onMouseEnter={handleHover(item.label)}
          onMouseLeave={handleHover("")}
          p={"0.5em 1em"}
          c={isHovered || isAnyChildActive ? "orange.4" : "light-grey.12"}
          fz={"15px"}
          fw={400}
          bg={hovered === item.label || isAnyChildActive ? "orange.12" : "none"}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1em",
            borderRadius: "5px",
            textDecoration: "none",
          }}
          href={item.link}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", gap: "0.5em" }}
            >
              {!isHovered && !isAnyChildActive && item.icon}
              {(isHovered || isAnyChildActive) &&
                React.cloneElement(item.icon, { fill: "#E86217" })}
              <div>{item.label}</div>
            </div>
            <IconChevronRight
              stroke={1.5}
              style={{
                width: rem(16),
                height: rem(16),
                transition: "transform 200ms ease",
                transform: item.initiallyOpened ? "rotate(90deg)" : "none",
              }}
            />
          </div>
        </Anchor>

        {item.initiallyOpened && (
          <div
            style={{
              paddingLeft: "1.5em",
            }}
          >
            {item.children.map((child: any) => (
              <Link
                target={child.link.includes("http") ? "_blank" : ""}
                to={child.link || ""}
                key={child.label}
                onMouseEnter={handleHover(child.label)}
                onMouseLeave={handleHover("")}
                style={{
                  fontSize: "15px",
                  fontWeight: 400,
                  color:
                    hovered === child.label || isActive(child.link)
                      ? "#282828"
                      : "#979797",
                  display: "flex",
                  alignItems: "center",
                  padding: "0.5em 1em",
                  gap: "1em",
                  borderRadius: "5px",
                  textDecoration: "none",
                }}
              >
                {child.label}
              </Link>
            ))}
          </div>
        )}
      </div>
    ) : (
      <Link
        to={item.link}
        key={item.label}
        onMouseEnter={handleHover(item.label)}
        onMouseLeave={handleHover("")}
        style={{
          color:
            hovered === item.label || isActive(item.link)
              ? "#E86217"
              : "#979797",

          backgroundColor:
            hovered === item.label || isActive(item.link) ? "#FFF2EB" : "",
          fontSize: "15px",
          fontWeight: 400,
          borderRadius: "5px",
          textDecoration: "none",
          padding: "0.5em 1em",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.5em",
          }}
        >
          {!isHovered && !isActive(item.link) && item.icon}
          {(isHovered || isActive(item.link)) &&
            React.cloneElement(item.icon, { fill: "#E86217" })}
          <div>{item.label}</div>
        </div>
      </Link>
    );
  });

  return (
    <div>
      {isTablet && (
        <div
          onClick={toggle}
          style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            zIndex: 10,
          }}
        />
      )}
      <Stack
        p={"1.5em"}
        bg={"light-grey.4"}
        w={"280px"}
        h={"100vh"}
        style={{
          zIndex: isTablet ? 999 : 0,
          border: "1px solid #E8E8E8",
          position: isTablet ? "absolute" : "relative",
          transition: "all 0.5s",
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        >
          <Stack pl={"0.7em"} justify="space-between">
            {!isTablet && (
              <div
                style={{
                  marginBottom: "1em",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/")}
              >
                <KpaliLogo style={{ width: rem(100) }} />
              </div>
            )}
            <Code bg={"none"} c={"#797979"} fw={400}>
              MAIN MENU
            </Code>
          </Stack>
          <Stack gap={"0.25em"}>{links}</Stack>
        </div>
      </Stack>
    </div>
  );
}

export default React.memo(UsernavBar);
