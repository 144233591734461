import { IconProps } from "./overviewIcon";

export const BioDataIcon = ({ className, width, height, fill }: IconProps) => (
  <svg
    width={width || "58"}
    height={height || "58"}
    className={className}
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M50.75 14.5H54.375V29H50.75V14.5ZM41.6875 9.0625H45.3125V29H41.6875V9.0625ZM32.625 18.125H36.25V29H32.625V18.125ZM29 54.375H25.375V43.5C25.3736 42.0583 24.8002 40.6761 23.7808 39.6567C22.7614 38.6373 21.3792 38.0639 19.9375 38.0625H12.6875C11.2458 38.0639 9.86361 38.6373 8.8442 39.6567C7.82478 40.6761 7.25144 42.0583 7.25 43.5V54.375H3.625V43.5C3.62788 41.0974 4.5836 38.7939 6.28252 37.095C7.98145 35.3961 10.2849 34.4404 12.6875 34.4375H19.9375C22.3401 34.4404 24.6436 35.3961 26.3425 37.095C28.0414 38.7939 28.9971 41.0974 29 43.5V54.375ZM16.3125 16.3125C17.3879 16.3125 18.4392 16.6314 19.3334 17.2289C20.2276 17.8264 20.9245 18.6756 21.3361 19.6692C21.7476 20.6627 21.8553 21.756 21.6455 22.8108C21.4357 23.8656 20.9178 24.8344 20.1574 25.5949C19.3969 26.3553 18.4281 26.8732 17.3733 27.083C16.3185 27.2928 15.2252 27.1851 14.2317 26.7736C13.2381 26.362 12.3889 25.6651 11.7914 24.7709C11.1939 23.8767 10.875 22.8254 10.875 21.75C10.875 20.3079 11.4479 18.9248 12.4676 17.9051C13.4873 16.8854 14.8704 16.3125 16.3125 16.3125ZM16.3125 12.6875C14.5201 12.6875 12.768 13.219 11.2776 14.2148C9.78732 15.2106 8.62576 16.626 7.93984 18.2819C7.25392 19.9379 7.07445 21.7601 7.42413 23.518C7.77381 25.276 8.63693 26.8907 9.90434 28.1582C11.1718 29.4256 12.7865 30.2887 14.5445 30.6384C16.3024 30.988 18.1246 30.8086 19.7806 30.1227C21.4365 29.4367 22.8519 28.2752 23.8477 26.7849C24.8435 25.2945 25.375 23.5424 25.375 21.75C25.375 20.5599 25.1406 19.3814 24.6852 18.2819C24.2297 17.1824 23.5622 16.1834 22.7207 15.3418C21.8791 14.5003 20.8801 13.8328 19.7806 13.3773C18.6811 12.9219 17.5026 12.6875 16.3125 12.6875Z"
      fill={fill || "black"}
    />
  </svg>
);
