import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Group, Stack, Box, NavLink, Divider } from "@mantine/core";
import AdminLayout from "../../../../layouts/adminLayout";
import { useGetRequestById } from "../../../../../hooks/admin/requests.hook";
import {
  RequestDetails,
  CommentsSection,
  PaymentSection,
  RequesterSection,
  AgentSection,
} from "./tabs";
import { useGetPaymentById } from "../../../../../hooks/admin/payments.hook";
import { useCurrentWorkspace } from "../../../../../hooks/user/user.workspace.hook";

function AdminRequestDetails() {
  const { requestId } = useParams();
  const { workspaceId, currentUser } = useCurrentWorkspace();
  const navigate = useNavigate();
  const { data, isLoading, refetch } = useGetRequestById(requestId!);
  const { data: paymentInfo, isLoading: paymentInfoLoading } =
    useGetPaymentById(requestId!);
  const [active, setActive] = useState("request-details");
  const requestDetails = data?.data;
  const tab = new URLSearchParams(window.location.search).get("tab");
  useEffect(() => {
    if (tab) {
      setActive(tab);
    }
  }, [tab]);

  const tabs = [
    {
      title: "Request Details",
      tab: "request-details",
      content: (
        <RequestDetails
          requestDetails={requestDetails?.request}
          isLoading={isLoading}
          workspaceId={workspaceId!}
          refetch={refetch}
          isAdmin={currentUser.appRole.includes("ADMIN")}
        />
      ),
    },
    {
      title: "Requester Section",
      tab: "requester-section",
      content: <RequesterSection requestDetails={requestDetails} />,
    },
    {
      title: "Payment Section",
      tab: "payment-section",
      content: (
        <PaymentSection
          paymentDetails={paymentInfo?.data}
          paymentLoading={paymentInfoLoading}
        />
      ),
    },
    {
      title: "Agent Section",
      tab: "agent-section",
      content: <AgentSection requestDetails={requestDetails} />,
    },
    {
      title: "Comments Section",
      tab: "comments-section",
      content: <CommentsSection requestDetails={requestDetails} />,
    },
  ];

  const handleTabChange = (tab: string) => {
    navigate(`/admin/requests/${requestId}?tab=${tab}`);
    setActive(tab);
  };

  return (
    <AdminLayout title="Requests">
      <Box>
        <Group gap={"2em"}>
          {tabs.map((tab, index) => (
            <Stack gap={"0"}>
              <NavLink
                c={active === tab.tab ? "orange.4" : "light-grey.10"}
                fw={active === tab.tab ? 700 : 500}
                label={tab.title}
                key={index}
                onClick={() => {
                  handleTabChange(tab.tab);
                  refetch();
                }}
              />
              {active === tab.title && <Divider color="orange.4" size={2} />}
            </Stack>
          ))}
        </Group>
        <Box>{tabs.find((tab) => tab.tab === active)?.content}</Box>
      </Box>
    </AdminLayout>
  );
}

export default AdminRequestDetails;
