import { Text, Button } from "@mantine/core";
import { Link } from "react-router-dom";
import { useGetVerifyEmail } from "../../../hooks/auth/auth.hook";
import AuthLayout from "../../layouts/auth";

function EmailVerified() {
  const queryString = window.location.search;

  const urlParams = new URLSearchParams(queryString);
  const uid = urlParams.get("user") || "";
  const code = urlParams.get("token") || "";
  // let { status } = useParams();
  // const status = useSearch({
  //   from: `emailVerified`,
  //   select: (search) => search.status,
  // });
  // if (!status) return <Navigate to={"/overview"} />;
  const { data } = useGetVerifyEmail(code, uid);

  // if (!code) return;

  return (
    <AuthLayout title="Email Verified">
      <Text ta={"center"}>
        A email has been verified. Login to get started.
      </Text>

      <Button fullWidth type="submit" component={Link} to="/login">
        Go to Login
      </Button>
      {/* <Stack w={"100%"}>
          <Group justify={"space-between"}>
            <Text c={{ base: "white", sm: "white", md: "dark" }}>
              <Anchor href="/login">Login</Anchor>
            </Text>
            <Text fw={"800"}>
              <Anchor href="/signup">Sign-up</Anchor>
            </Text>
          </Group>
        </Stack> */}
    </AuthLayout>
  );
}

export default EmailVerified;
