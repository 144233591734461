import { Container, Flex, Stack, Image, Text, em, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import GetInTouchSimple from "./contactUsForm";
import AddressIcon from "../../assets/svg/addressIcon.svg";
import PhoneIcon from "../../assets/svg/phoneIcon.svg";
import EmailIcon from "../../assets/svg/emailIcon.svg";

const data = [
  {
    icon: AddressIcon,
    header: "Address",
    description:
      "Level 3, Suite 2, Dominion Plaza, 140/141 Lekki - Epe Expy, Igbo Efon, Lagos",
  },
  {
    icon: PhoneIcon,
    header: "Phone",
    description: "+234 7078308551, +234 8142077473",
  },
  {
    icon: EmailIcon,
    header: "Email",
    description: "info@kpali.ng, support@kpali.ng",
  },
];

function ContactUs() {
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
  return (
    <Container size={"lg"}>
      <Flex
        mt={{ base: "2em", sm: "5em" }}
        mb={{ base: "2em", sm: "5em" }}
        justify={"space-between"}
        direction={{ base: "column", sm: "row" }}
        gap={{ base: "3em", sm: "2em" }}
      >
        {isMobile && (
          <Title
            order={2}
            ta="left"
            fz={"1.75em"}
            style={{
              letterSpacing: " -0.00625rem",
            }}
          >
            Contact Us
          </Title>
        )}
        <Stack w={{ base: "100%", sm: "35%" }}>
          {data.map((_, index) => (
            <Flex
              gap={"1em"}
              key={index}
              align={"center"}
              style={{
                flex: 1,
                backgroundColor: "white",
                padding: "0.8em",
                borderRadius: "1em",
                border: "1px #E8E8E8 solid",
              }}
            >
              <Image
                bg={"#FEEEE5"}
                p={"0.5em"}
                radius={"50%"}
                src={_.icon}
                w={"45"}
                h={"45"}
              />
              <Stack gap={"0.3em"}>
                <Text fw={750}>{_.header}</Text>
                <Text>{_.description}</Text>
              </Stack>
            </Flex>
          ))}
        </Stack>
        <GetInTouchSimple />
      </Flex>
    </Container>
  );
}

export default ContactUs;
