import React from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  QueryClient as ReactQuery,
  QueryClientProvider as ReactQueryClientProvider,
} from "react-query";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import "process/browser";
import "./index.css";
import "@mantine/core/styles.css";
import { Group, MantineProvider, Text, createTheme } from "@mantine/core";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  ErrorPage,
  Home,
  About,
  Certificates,
  Contact,
  SignUp,
  LogIn,
  ResetPassword,
  ForgotPassword,
  Dashboard,
  UserCertificateVerification,
  UserDocumentVerification,
  AdminDashboardPage,
  Users,
  Workspaces,
  AdminServices,
  AdminRequests,
  AdminRequestDetails,
  Settings,
  EditUser,
  Agents,
  RequestedServices,
  PendingRequests,
  CompletededRequests,
  EditService,
  AcceptInvite,
  RequestDetails,
  VerifyEmail,
  Analytics,
  EmailVerified,
  Legal,
} from "./containers";
import AdminPaymentsPage from "./containers/adminPaymentsPage";
import WhatsappIcon from "./components/assets/svg/whatsappIcon.svg";

const queryClient = new QueryClient();
const reactQuery = new ReactQuery();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const WhatsAppChat = () => (
  <a
    href="https://wa.me/+2348090666746"
    target="_blank"
    rel="noopener noreferrer"
    style={{
      bottom: "150px",
      right: "25px",
      position: "fixed",
      textDecoration: "none",
    }}
  >
    <Group>
      <div
        style={{
          background: "white",
          color: "gray",
          padding: "8px",
          borderRadius: "10px",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      >
        <Text>{"Chat us on WhatsApp"}</Text>
      </div>
      <img src={WhatsappIcon} alt="WhatsApp Icon" width={60} height={60} />
    </Group>
  </a>
);

const theme = createTheme({
  fontFamily:
    "Mulish,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji",
  headings: {
    fontFamily:
      "Mulish,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji",
  },
  colors: {
    orange: [
      "#FFE7D9",
      "#FDCBAF",
      "#FFAC7D",
      "#FB8C4E",
      "#E86217",
      "#BA4605",
      "#933906",
      "#652501",
      "#3D1600",
      "#A0522D",
      "#DD8705",
      "#FFF0DA",
      "#FFF2EB",
    ],
    gray: [
      "#E6E6E6",
      "#C7C7C7",
      "#A9A9A9",
      "#8F8F8F",
      "#707070",
      "#616161",
      "#666666",
      "#575757",
      "#2E2E2E",
      "#424242",
    ],
    green: [
      "#C5FFBF",
      "#88DB81",
      "#63CF59",
      "#52BD48",
      "#2A9E20",
      "#207419",
      "#1A5A14",
      "#155010",
      "#16380B",
      "#355E3B",
      "#319755",
      "#E2F8E5",
    ],
    "light-grey": [
      "#F2F2F2",
      "#D9D9D9",
      "#FAFAFA",
      "#F8F8F8",
      "#FFF",
      "#E8E8E8",
      "#E7E7E7",
      "#F5F4F3",
      "#797979",
      "#CDCDCD",
      "#B5B5B5",
      "#A9B3C3",
      "#979797",
    ],
    dark: [
      "#C9C9C9",
      "#B8B8B8",
      "#828282",
      "#696969",
      "#282828",
      "#4A4A4A",
      "#404040",
      "#383838",
      "#2E2E2E",
      "#393939",
      "#6A778B",
      "#000000",
      "#333333",
      "#717171",
    ],
    blue: [
      "#0C8CE9",
      "#E6F7FF",
      "#BAE7FF",
      "#91D5FF",
      "#69C0FF",
      "#40A9FF",
      "#1890FF",
      "#E6F7FF",
      "#BAE7FF",
      "#91D5FF",
      "#69C0FF",
      "#40A9FF",
      "#1890FF",
    ],
  },
  primaryColor: "orange",
  primaryShade: 4,
  breakpoints: {
    xs: "30em",
    sm: "48em",
    md: "64em",
    lg: "74em",
    xl: "92em",
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Home />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/about",
    element: (
      <>
        <About />
      </>
    ),
    errorElement: <ErrorPage />,
  },

  {
    path: "/certificates",
    element: (
      <>
        <Certificates />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/contact",
    element: (
      <>
        <Contact />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/legal",
    element: (
      <>
        <Legal />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/signup",
    element: (
      <>
        <SignUp />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/emailverified",
    element: (
      <>
        <EmailVerified />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: (
      <>
        <LogIn />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/resetpassword",
    element: (
      <>
        <ResetPassword />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/forgotpassword",
    element: (
      <>
        <ForgotPassword />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/acceptinvite",
    element: (
      <>
        <AcceptInvite />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/verifyemail",
    element: (
      <>
        <VerifyEmail />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/overview",
    element: (
      <>
        <Dashboard />
      </>
    ),
    errorElement: <ErrorPage />,
  },

  {
    path: "/service/certificate",
    element: (
      <>
        <UserCertificateVerification />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/service/document",
    element: (
      <>
        <UserDocumentVerification />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/requested",
    element: (
      <>
        <RequestedServices />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/requested/:requestId",
    element: (
      <>
        <RequestDetails />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/pending",
    element: (
      <>
        <PendingRequests />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/completed",
    element: (
      <>
        <CompletededRequests />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/admin/dashboard",
    element: (
      <>
        <AdminDashboardPage />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/admin/agents",
    element: (
      <>
        <Agents />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/admin/users",
    element: (
      <>
        <Users />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/admin/users/:userId",
    element: (
      <>
        <EditUser />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/admin/workspaces",
    element: (
      <>
        <Workspaces />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/admin/services",
    element: (
      <>
        <AdminServices />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/admin/services/:serviceId",
    element: (
      <>
        <EditService />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/admin/payments",
    element: (
      <>
        <AdminPaymentsPage />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/admin/requests",
    element: (
      <>
        <AdminRequests />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/admin/analytics",
    element: (
      <>
        <Analytics />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/admin/requests/:requestId",
    element: (
      <>
        <AdminRequestDetails />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/settings",
    element: (
      <>
        <Settings />
      </>
    ),
    errorElement: <ErrorPage />,
  },
]);

root.render(
  <React.StrictMode>
    {/* <AuthCheck>
      <AdminCheck> */}
    <MantineProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryClientProvider client={reactQuery}>
          <SkeletonTheme
            baseColor={theme.colors?.gray?.[0]}
            highlightColor={theme.colors?.gray?.[1]}
          >
            <ToastContainer />
            <WhatsAppChat />

            <RouterProvider router={router} />
          </SkeletonTheme>
        </ReactQueryClientProvider>
      </QueryClientProvider>
    </MantineProvider>
    {/* </AdminCheck>
    </AuthCheck> */}
  </React.StrictMode>
);
