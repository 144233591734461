import {
  Container,
  Image,
  Stack,
  Title,
  Box,
  Group,
  Button,
} from "@mantine/core";
import StartVerificationImage from "../../assets/images/start-verification.png";
import { NavLink } from "react-router-dom";
import useResponsive from "../../../hooks/useResponsive";

function StartVerification() {
  const { isMobile } = useResponsive();
  return (
    <Container size={"lg"} w={"100%"} style={{ textAlign: "center" }}>
      <Box
        h={{ base: "250px", sm: "325px", md: "350px" }}
        m={"60px 0px"}
        style={{
          position: "relative",
          background:
            "linear-gradient(to right, rgba(232, 110, 23, 1), rgba(255, 187, 60, 1))",

          width: "100%",
          borderRadius: isMobile ? "20px" : "35px",
        }}
      >
        {!isMobile && (
          <Image
            src={StartVerificationImage}
            alt="Verification Banner"
            w={{ base: "250px", sm: "275px", md: "350px" }}
            top={{ base: "35px", sm: "85px", md: "47px" }}
            style={{
              position: "absolute",
              left: "200px",
              transform: "translateX(-50%)",
              height: "auto",
              borderRadius: "10px",
            }}
          />
        )}
        <Stack align="center" justify="center" h={"100%"} w={"100%"}>
          <Title
            order={2}
            fw={600}
            fz={{ base: "1.75em", sm: "2.5em", md: "49px" }}
            c="white"
            ta={"center"}
          >
            Start your Verification <br /> Process Today!
          </Title>
          <Group>
            <NavLink to={"/signup"} style={{ textDecoration: "none" }}>
              <Button
                fw={{ base: 600, sm: 800 }}
                fz={{ base: "12px", sm: "14px" }}
                radius="15px"
                size={"lg"}
                variant={"outline"}
                style={{
                  borderRadius: "7px",
                  color: "white",
                  backgroundColor: "transparent",
                  borderColor: "white",
                }}
              >
                Create an account
              </Button>
            </NavLink>
            <NavLink to={"/login"} style={{ textDecoration: "none" }}>
              <Button
                fw={{ base: 600, sm: 800 }}
                fz={{ base: "12px", sm: "14px" }}
                radius="15px"
                size={"lg"}
                variant={"default"}
                style={{
                  borderRadius: "7px",
                  border: "none",
                }}
              >
                Login
              </Button>
            </NavLink>
          </Group>
        </Stack>
      </Box>
    </Container>
  );
}

export default StartVerification;
