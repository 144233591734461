import { Container, Image, Accordion, Box, Title } from "@mantine/core";
import OrangeArrowIcon from "../../assets/svg/orangeArrowIcon.svg";

const data = [
  {
    question: "Why is certificate verification important?",
    answer:
      "Certificate verification is crucial to prevent fraud and misrepresentation. It ensures that individuals or organizations claiming to have specific qualifications or credentials actually possess them.",
  },
  {
    question: "How can I verify a certificate?",
    answer:
      "You can typically verify a certificate by contacting the issuing institution or using online verification tools like Iverify.",
  },
  {
    question: "What types of certificates can be verified?",
    answer:
      "You can verify a wide range of certificates, including educational degrees, professional certifications, birth certificates, marriage certificates, and more.",
  },
  {
    question: "What information do I need for certificate verification?",
    answer:
      "The information required for certificate verification may vary, but typically, you'll need the name of the certificate holder, the issuing institution, and the date of issuance.",
  },
  {
    question: "Do we verify a certificate from another country?",
    answer: "Yes, we can verify certificates issued in other countries.",
  },
  {
    question: "How long does certificate verification take?",
    answer:
      "The time it takes for certificate verification can vary widely. Some certificates can be verified in days, while others may take several weeks.",
  },
  {
    question: "Can certificates be forged or faked?",
    answer:
      "Yes, certificates can be forged or faked, which is why certificate verification is essential",
  },
];

function FaqQuestions() {
  return (
    <Box pb={{ base: "2em", sm: "5em" }}>
      <Container size="lg">
        <Title fw={700} fz={"40px"} c={"dark.11"} ta={"center"}>
          Frequently asked questions (FAQs)
        </Title>
        <Accordion
          mt={{ base: "2em", sm: "5em" }}
          //   ml={{ base: "2em", sm: "5em" }}
          variant="separated"
          chevronPosition="right"
          //   disableChevronRotation
          chevron={<Image w={"20"} h={"13"} src={OrangeArrowIcon} />}
        >
          {data.map((item, index) => (
            <Accordion.Item
              value={index.toString()}
              key={index}
              pl={"1em"}
              bg={"white"}
              p={"0.5em"}
              style={{ border: "1px #D3D0D0 solid" }}
            >
              <Accordion.Control>{item.question}</Accordion.Control>
              <Accordion.Panel>{item.answer}</Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
      </Container>
    </Box>
  );
}

export default FaqQuestions;
