import { Group, Burger, Button, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { NavLink } from "react-router-dom";
import { KpaliLogo } from "../../components/assets/icons";
import { HeaderNavLink } from "../../components/primitives/headerMenu/headerMenu";
import useResponsive from "../../hooks/useResponsive";

const links = [
  { to: "/", label: "Home" },
  { to: "/about", label: "About" },
  { to: "/certificates", label: "Certificates" },
  { to: "/contact", label: "Contact" },
  // { to: "/login", label: "Login" },
];

export function Header() {
  const [opened, { toggle }] = useDisclosure(false);
  const { isLaptop } = useResponsive();
  return (
    <header>
      <Group justify={"space-between"} h="5.1em" align="center">
        <Group gap={"2em"}>
          <KpaliLogo />
          <Group
            visibleFrom="sm"
            style={{
              gap: "2em",
            }}
          >
            {links.map((link) => (
              <HeaderNavLink
                key={link.to}
                to={link.to}
                label={link.label}
                isLoginLink={link.label === "Login"}
              />
            ))}
          </Group>
        </Group>
        <Group gap="0.5em" visibleFrom="sm">
          <NavLink to="/login" style={{ textDecoration: "none" }}>
            <Button radius="7px" size="md" variant="default">
              <Text c="dark.9" fw={800} size="16px">
                Login
              </Text>
            </Button>
          </NavLink>

          <NavLink to="/signup" style={{ textDecoration: "none" }}>
            <Button radius="7px" size="md">
              <Text c="light-grey.4" fw={700} size="14px">
                {isLaptop ? "Sign Up" : "Create your account"}
              </Text>
            </Button>
          </NavLink>
        </Group>

        <Burger opened={opened} onClick={toggle} hiddenFrom="xs" size="sm" />
      </Group>
    </header>
  );
}
