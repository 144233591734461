import { IconProps } from "./overviewIcon";

export const WaecIcon = ({ className, width, height, fill }: IconProps) => (
  <svg
    width={width || "49"}
    height={height || "49"}
    className={className}
    viewBox="0 0 49 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.4 7.83998V40.18H5.87996V7.83998H29.4ZM33.32 3.91998H1.95996V45.08H33.32V3.91998ZM8.81996 11.76H26.46V15.68H8.81996V11.76ZM8.81996 18.62H26.46V22.54H8.81996V18.62ZM8.81996 25.48H26.46V29.4H8.81996V25.48ZM8.81996 32.34H26.46V36.26H8.81996V32.34ZM39.2 11.76H47.04V39.2H39.2V11.76ZM43.1258 0.97998C40.9757 0.97998 39.2 2.70968 39.2 4.83824V8.81998H47.04V4.83824C47.04 2.70968 45.2671 0.97998 43.1258 0.97998ZM39.1392 42.14L43.0994 48.3992L47.04 42.14H39.1392Z"
      //   fill="black"
      fill={fill || "black"}
    />
  </svg>
);
