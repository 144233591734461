import API from "../../api/api";

const contactURL = "/contact";

type IContactForm = {
  firstName: string;
  email: string;
  phoneNumber?: string;
  subject?: string;
  message: string;
};

export const postContactForm = async (data: IContactForm) => {
  const res = await API.post(`${contactURL}`, data);
  return res.data;
};
