import Cookies from "js-cookie";
import { useEffect } from "react";
import { logout } from "../../../hooks/auth/auth.hook";

const publicRoutes = [
  "/about",
  "/certificates",
  "/contact",
  "/signup",
  "/login",
  "/resetpassword",
  "/forgotpassword",
  "/acceptinvite",
  "/verifyemail",
  "/emailverified",
];

const AuthCheck = ({ children }: { children: React.ReactNode }) => {
  const token = Cookies.get("token");
  const refreshToken = Cookies.get("refreshToken");
  const pathname = window.location.pathname;

  useEffect(() => {
    if (
      !token &&
      !refreshToken &&
      !publicRoutes.includes(pathname) &&
      !publicRoutes.some((route) => pathname.startsWith(route)) &&
      pathname !== "/"
    ) {
      logout();
    }
  }, [token, children, pathname, refreshToken]);

  return children;
};

export const AdminCheck = ({ children }: { children: React.ReactNode }) => {
  // const role = Cookies.get("role");
  // const token = Cookies.get("token");
  // const refreshToken = Cookies.get("refreshToken");
  // const pathname = window.location.pathname;
  // useEffect(() => {
  //   if (
  //     role?.replaceAll(`"`, "") !== AppRoles.ADMIN &&
  //     pathname.startsWith("/admin")
  //   ) {
  //     window.location.replace("/overview");
  //   }
  // }, [role, token, refreshToken, children, pathname]);

  return children;
};

export const setCookies = ({ name, data }: any) => {
  // expires in 30 days
  Cookies.set(name, JSON.stringify(data), {
    httpOnly: false,
    secure: true,
    expires: 30,
    sameSite: "none",
    path: "/",
  });
};

export default AuthCheck;
