import { IconProps } from "./overviewIcon";

export const ProfessionalIcon = ({
  className,
  width,
  height,
  fill,
}: IconProps) => (
  <svg
    width={width || "55"}
    height={height || "55"}
    className={className}
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42.9688 17.1875L45.7067 22.3438L51.5625 23.0553L47.2656 26.9277L48.125 32.6562L42.9688 29.4336L37.8125 32.6562L38.6719 26.9277L34.375 23.0553L40.3906 22.3438L42.9688 17.1875ZM37.8125 51.5625H34.375V42.9688C34.3723 40.6904 33.466 38.5061 31.8549 36.8951C30.2439 35.284 28.0596 34.3777 25.7812 34.375H15.4688C13.1904 34.3777 11.0061 35.284 9.39507 36.8951C7.78402 38.5061 6.87773 40.6904 6.875 42.9688V51.5625H3.4375V42.9688C3.44205 39.7793 4.71108 36.7217 6.96639 34.4664C9.22171 32.2111 12.2793 30.942 15.4688 30.9375H25.7812C28.9707 30.942 32.0283 32.2111 34.2836 34.4664C36.5389 36.7217 37.808 39.7793 37.8125 42.9688V51.5625ZM20.625 6.875C22.3247 6.875 23.9862 7.37901 25.3994 8.32331C26.8127 9.2676 27.9142 10.6098 28.5646 12.1801C29.215 13.7504 29.3852 15.4783 29.0536 17.1453C28.722 18.8123 27.9036 20.3436 26.7017 21.5454C25.4998 22.7473 23.9686 23.5658 22.3016 23.8974C20.6345 24.229 18.9066 24.0588 17.3363 23.4083C15.766 22.7579 14.4239 21.6564 13.4796 20.2432C12.5353 18.8299 12.0312 17.1684 12.0312 15.4688C12.0312 13.1895 12.9367 11.0037 14.5483 9.39205C16.1599 7.78041 18.3458 6.875 20.625 6.875ZM20.625 3.4375C18.2454 3.4375 15.9193 4.14312 13.9408 5.46513C11.9623 6.78714 10.4202 8.66617 9.50957 10.8646C8.59896 13.063 8.3607 15.4821 8.82493 17.8159C9.28916 20.1498 10.435 22.2935 12.1176 23.9761C13.8002 25.6587 15.944 26.8046 18.2778 27.2688C20.6117 27.7331 23.0307 27.4948 25.2292 26.5842C27.4276 25.6736 29.3066 24.1315 30.6286 22.153C31.9506 20.1744 32.6562 17.8483 32.6562 15.4688C32.6562 12.2779 31.3887 9.21767 29.1324 6.96137C26.8761 4.70507 23.8159 3.4375 20.625 3.4375Z"
      //   fill="black"
      fill={fill || "black"}
    />
  </svg>
);
