import { IconProps } from "./overviewIcon";

export const NecoIcon = ({ className, width, height, fill }: IconProps) => (
  <svg
    width={width || "57"}
    height={height || "57"}
    className={className}
    viewBox="0 0 57 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.8125 38C17.4976 38 17.1955 38.1251 16.9728 38.3478C16.7501 38.5705 16.625 38.8726 16.625 39.1875C16.625 39.5024 16.7501 39.8045 16.9728 40.0272C17.1955 40.2499 17.4976 40.375 17.8125 40.375H39.1875C39.5024 40.375 39.8045 40.2499 40.0272 40.0272C40.2499 39.8045 40.375 39.5024 40.375 39.1875C40.375 38.8726 40.2499 38.5705 40.0272 38.3478C39.8045 38.1251 39.5024 38 39.1875 38H17.8125ZM16.625 43.9375C16.625 43.6226 16.7501 43.3205 16.9728 43.0978C17.1955 42.8751 17.4976 42.75 17.8125 42.75H32.0625C32.3774 42.75 32.6795 42.8751 32.9022 43.0978C33.1249 43.3205 33.25 43.6226 33.25 43.9375C33.25 44.2524 33.1249 44.5545 32.9022 44.7772C32.6795 44.9999 32.3774 45.125 32.0625 45.125H17.8125C17.4976 45.125 17.1955 44.9999 16.9728 44.7772C16.7501 44.5545 16.625 44.2524 16.625 43.9375Z"
      //   fill="black"
      fill={fill || "black"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.8462 18.5428C24.756 18.3265 24.6037 18.1416 24.4087 18.0116C24.2137 17.8816 23.9845 17.8123 23.7501 17.8123C23.5157 17.8123 23.2865 17.8816 23.0915 18.0116C22.8964 18.1416 22.7442 18.3265 22.654 18.5428L18.2104 29.2066L18.1902 29.2564L16.7165 32.7928C16.5953 33.0835 16.5945 33.4105 16.7143 33.7017C16.8341 33.993 17.0647 34.2248 17.3554 34.3461C17.6461 34.4673 17.9731 34.4681 18.2643 34.3483C18.5556 34.2285 18.7874 33.9979 18.9087 33.7072L20.089 30.875H27.4112L28.5915 33.7072C28.7128 33.9979 28.9445 34.2285 29.2358 34.3483C29.5271 34.4681 29.8541 34.4673 30.1448 34.3461C30.4355 34.2248 30.6661 33.993 30.7859 33.7017C30.9057 33.4105 30.9049 33.0835 30.7837 32.7928L29.31 29.2564L29.2898 29.2066L24.8462 18.5428ZM23.7501 22.0875L26.422 28.5H21.0782L23.7501 22.0875Z"
      //   fill="black"
      fill={fill || "black"}
    />
    <path
      d="M35.625 24.9375C35.9399 24.9375 36.242 25.0626 36.4647 25.2853C36.6874 25.508 36.8125 25.8101 36.8125 26.125V28.5H39.1875C39.5024 28.5 39.8045 28.6251 40.0272 28.8478C40.2499 29.0705 40.375 29.3726 40.375 29.6875C40.375 30.0024 40.2499 30.3045 40.0272 30.5272C39.8045 30.7499 39.5024 30.875 39.1875 30.875H36.8125V33.25C36.8125 33.5649 36.6874 33.867 36.4647 34.0897C36.242 34.3124 35.9399 34.4375 35.625 34.4375C35.3101 34.4375 35.008 34.3124 34.7853 34.0897C34.5626 33.867 34.4375 33.5649 34.4375 33.25V30.875H32.0625C31.7476 30.875 31.4455 30.7499 31.2228 30.5272C31.0001 30.3045 30.875 30.0024 30.875 29.6875C30.875 29.3726 31.0001 29.0705 31.2228 28.8478C31.4455 28.6251 31.7476 28.5 32.0625 28.5H34.4375V26.125C34.4375 25.8101 34.5626 25.508 34.7853 25.2853C35.008 25.0626 35.3101 24.9375 35.625 24.9375Z"
      //   fill="black"
      fill={fill || "black"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.125 17.8125L33.25 4.75H16.625C15.3652 4.75 14.157 5.25044 13.2662 6.14124C12.3754 7.03204 11.875 8.24022 11.875 9.5V47.5C11.875 48.7598 12.3754 49.968 13.2662 50.8588C14.157 51.7496 15.3652 52.25 16.625 52.25H40.375C41.6348 52.25 42.843 51.7496 43.7338 50.8588C44.6246 49.968 45.125 48.7598 45.125 47.5V17.8125ZM32.0625 17.8125C32.0625 18.1274 32.1876 18.4295 32.4103 18.6522C32.633 18.8749 32.9351 19 33.25 19H42.75V47.5C42.75 48.1299 42.4998 48.734 42.0544 49.1794C41.609 49.6248 41.0049 49.875 40.375 49.875H16.625C15.9951 49.875 15.391 49.6248 14.9456 49.1794C14.5002 48.734 14.25 48.1299 14.25 47.5V9.5C14.25 8.87011 14.5002 8.26602 14.9456 7.82062C15.391 7.37522 15.9951 7.125 16.625 7.125H32.0625V17.8125ZM40.8358 16.625L34.4375 9.58669V16.625H40.8358Z"
      //   fill="black"
      fill={fill || "black"}
    />
  </svg>
);
