import { Text, Stack, Title, Image, Flex, Container } from "@mantine/core";
import { LandingLayout } from "../components/layouts/landing";
import OurTeam from "../components/primitives/aboutPage/ourTeam";
import AboutImage from "../components/primitives/aboutPage/aboutImage";
import WhoWeAreImage from "../components/assets/images/whoWeAreImage.png";
import Flag from "../components/assets/images/flag.png";
import House from "../components/assets/images/house.png";
import Target from "../components/assets/images/target.png";
import { withLanding } from "../hoc/withLanding";
import useResponsive from "../hooks/useResponsive";

const About = () => {
  const { isTablet } = useResponsive();
  return (
    <LandingLayout>
      <AboutImage
        image={WhoWeAreImage}
        headerText={
          <Text inherit ta={"center"}>
            Helping Organisations
            <br />
            to be exceptional
          </Text>
        }
      />
      <Container
        size={"lg"}
        w={"100%"}
        style={{
          marginBottom: isTablet ? "3em" : "5em",
        }}
      >
        <Flex
          justify={"space-between"}
          gap={"1em"}
          align={"center"}
          direction={isTablet ? "column" : "row"}
        >
          <Stack
            h={isTablet ? 250 : 300}
            gap={"1em"}
            w={"100%"}
            style={{
              ...(!isTablet && {
                flex: 1,
              }),
            }}
          >
            <Stack
              h={isTablet ? 250 : 300}
              w={"100%"}
              bg={"#D6EEFFD4"}
              p={{ base: "1em", md: "2em 3em" }}
              align="center"
              justify="center"
              style={{
                position: "relative",
                border: "1px solid #0C8CE9",
                borderRadius: "16px",
                flex: 1,
              }}
            >
              <Image
                src={Flag}
                alt="Flag"
                w={{ base: "110px", sm: "135px", md: "160px" }}
                style={{
                  position: "absolute",
                  right: "-10px",
                  bottom: "-22.5px",
                }}
              />
              <Stack gap={0}>
                <Title
                  order={3}
                  fw={600}
                  fz={{
                    base: "24px",
                    sm: "30px",
                    md: "39px",
                  }}
                  c="dark.11"
                >
                  Our Mission
                </Title>
                <Text fw={500} fz={{ base: "14px", md: "16px" }} c={"dark.4"}>
                  To accelerate digital inclusion and economic growth through
                  accessed to verified data in Africa.
                </Text>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            gap={"1em"}
            w={"100%"}
            style={{
              flex: 1,
            }}
          >
            <Stack
              h={300}
              bg={"#FFF"}
              p={{ base: "1em", md: "2em 3em" }}
              align="center"
              justify="center"
              style={{
                border: "1px solid #FFF",
                borderRadius: "16px",
                position: "relative",
              }}
            >
              <Image
                src={House}
                alt="House"
                w={{ base: "100px", sm: "125px", md: "150px" }}
                style={{
                  position: "absolute",
                  right: "0px",
                  bottom: "0px",
                }}
              />
              <Stack gap={0}>
                <Title
                  order={3}
                  fw={600}
                  fz={{
                    base: "24px",
                    sm: "30px",
                    md: "39px",
                  }}
                  c="dark.11"
                >
                  About Us
                </Title>
                <Text fw={500} fz={{ base: "14px", md: "16px" }} c={"dark.4"}>
                  Kpali is a product of Iverify.ng, a digital Identity and
                  background check company. We are technology driven and
                  integrate proven expertise to provide holistic verification.
                  We provides access to business, basic services and ensures
                  compliance in business processes.
                </Text>
              </Stack>
            </Stack>
            <Stack
              h={300}
              w={"100%"}
              bg={"#F6F6F6"}
              p={{ base: "1em", md: "2em 3em" }}
              align="center"
              justify="center"
              style={{
                border: "1px solid #C9C8C8",
                borderRadius: "16px",
                position: "relative",
              }}
            >
              <Image
                src={Target}
                alt="target"
                w={{ base: "100px", sm: "125px", md: "150px" }}
                style={{
                  position: "absolute",
                  right: "0px",
                  bottom: "0px",
                }}
              />
              <Stack gap={0}>
                <Title
                  order={3}
                  fw={600}
                  fz={{
                    base: "24px",
                    sm: "30px",
                    md: "39px",
                  }}
                  c="dark.11"
                >
                  Our Vision
                </Title>
                <Text fw={500} fz={{ base: "14px", md: "16px" }} c={"dark.4"}>
                  To provide accurate, reliable and comprehensive information
                  about individuals to support business processes, drive
                  sustainability and promote data driven decision making by
                  organizations and stakeholders.
                </Text>
              </Stack>
            </Stack>
          </Stack>
        </Flex>
      </Container>
      <OurTeam />
    </LandingLayout>
  );
};

export default withLanding(About);
