import { LandingLayout } from "../components/layouts/landing";
import { CertificateServices } from "../components/primitives/certificatesPage/certificateCards";
import { HeroBullets } from "../components/primitives/homePage/hero";
import VerificationProcess from "../components/primitives/homePage/verificationProcesses";
import VerificationServices from "../components/primitives/homePage/verificationServices";
import StartVerification from "../components/primitives/homePage/startVerification";
import { withLanding } from "../hoc/withLanding";

const Home = () => {
  return (
    <LandingLayout>
      <HeroBullets />
      <VerificationProcess />
      <VerificationServices />
      <CertificateServices />
      <StartVerification />
    </LandingLayout>
  );
};

export default withLanding(Home);
