import { Text } from "@mantine/core";
import { NavLink } from "react-router-dom";

interface IHeaderLink {
  to: string;
  isLoginLink?: boolean;
  label: string;
}

export const HeaderNavLink: React.FC<IHeaderLink> = ({
  to,
  isLoginLink,
  label,
}) => {
  const isActive = window.location.pathname === to;
  return (
    <NavLink
      to={to}
      style={{
        textDecoration: "none",
      }}
    >
      <Text
        fw={500}
        fs={"normal"}
        style={(theme) => ({
          color: isActive ? theme.colors.dark[11] : theme.colors.dark[9],
        })}
      >
        {label}
      </Text>
    </NavLink>
  );
};
