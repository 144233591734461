import { Container, Stack, Title, Text } from "@mantine/core";

function VerificationServices() {
  return (
    <Container size={"lg"} w={"100%"}>
      <Stack align="flex-start" gap={0} p={"20px 0px"}>
        <Title
          order={2}
          fw={700}
          fz={{ base: "1.75em", sm: "2.5em", md: "49px" }}
          c="#282828CC"
        >
          Top Verification Services
        </Title>
        <Text
          fw={500}
          fz={{ base: "1.0em", sm: "16px", md: "20px" }}
          c={"dark.9"}
        >
          A robust and secure platform designed to streamline the authentication
        </Text>
      </Stack>
    </Container>
  );
}

export default VerificationServices;
