import * as Yup from "yup";
import { ServiceCategory } from "../../enums/enums";

export const UpdateServiceSchema = Yup.object().shape({
  name: Yup.string().required("Service name is required").trim(),
  description: Yup.string().required("Description is required").trim(),
  // nairaAmount: Yup.number().required("Cost amount is required"),
  dollarAmount: Yup.number().required("Cost amount is required"),
  visible: Yup.boolean().required("Visibility is required"),
  available: Yup.boolean().required("Availability is required"),
  regexFormat: Yup.string().required("Regex format is required").trim(),
  serviceProvider: Yup.string().required("Service provider is required").trim(),
  // serviceCode: Yup.string().required("Service code is required").trim(),
  serviceCategory: Yup.mixed()
    .oneOf(Object.values(ServiceCategory))
    .required("Service category is required"),
});

export type UpdateServiceDTO = Yup.InferType<typeof UpdateServiceSchema>;
