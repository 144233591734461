import { IconProps } from "./overviewIcon";
export const MaritalIcon = ({ className, width, height, fill }: IconProps) => (
  <svg
    width={width || "55"}
    height={height || "55"}
    className={className}
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.6249 50.4167C24.8794 50.4167 28.9597 48.7266 31.9681 45.7182C34.9765 42.7098 36.6666 38.6296 36.6666 34.375C36.6666 30.1205 34.9765 26.0403 31.9681 23.0319C28.9597 20.0235 24.8794 18.3334 20.6249 18.3334C16.3704 18.3334 12.2901 20.0235 9.28175 23.0319C6.27335 26.0403 4.58325 30.1205 4.58325 34.375C4.58325 38.6296 6.27335 42.7098 9.28175 45.7182C12.2901 48.7266 16.3704 50.4167 20.6249 50.4167Z"
      // stroke="#E86217"
      stroke={fill || "black"}
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M34.3749 36.6667C38.6294 36.6667 42.7097 34.9766 45.7181 31.9682C48.7265 28.9598 50.4166 24.8796 50.4166 20.625C50.4166 16.3705 48.7265 12.2903 45.7181 9.28187C42.7097 6.27347 38.6294 4.58337 34.3749 4.58337C30.1204 4.58337 26.0401 6.27347 23.0317 9.28187C20.0234 12.2903 18.3333 16.3705 18.3333 20.625C18.3333 24.8796 20.0234 28.9598 23.0317 31.9682C26.0401 34.9766 30.1204 36.6667 34.3749 36.6667Z"
      // stroke="#E86217"
      stroke={fill || "black"}
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
