import CertificateVerification from "../components/primitives/dashboard/user/service/certificateVerification";

import { withAuth } from "../hoc/withAuth";

function UserCertificateVerification() {
  return (
    <div>
      <CertificateVerification />
    </div>
  );
}

export default withAuth(UserCertificateVerification);
